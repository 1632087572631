<template>
  <vuestic-widget v-if="profileData" :loading="loading">
    <template slot="header">
      <span class="title">Twilio Profile <span v-if="profileData.friendlyName">for <b>{{ profileData.friendlyName }}</b></span></span>
    </template>
    <div class="twilioProfie-create-page">
      <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }" ref="agencyForm">
        <form @submit.prevent="handleSubmit(submit)">
          <h5 class="text-primary font-weight-bold">Business Profile and A2P10DLC Registration</h5>
          <div class="step-wrapper mt-5">
            <div class="step-header">
              <div v-for="step in steps" :key="step.index" class="step-header-item step1"
                v-bind:class="{ 'active': isStepActive(step.index) }">
                <div class="number">{{ step.index }}</div>
                <div class="label">{{ step.label }}</div>
                <div class="actions">
                  <div v-if="step.index == 2 && isStepActive(step.index)" class="d-block">
                    <button type="button" class="btn btn-primary" :disabled="processing" @click="validateProfile">
                      <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                        style="margin: auto;" />
                      <span v-else>Validate Now</span>
                    </button>
                    <button v-if="showResubmit" type="button" class="btn btn-primary ml-2" :disabled="processing" @click="updateProfile">
                      <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                        style="margin: auto;" />
                      <span v-else>Resubmit</span>
                    </button>
                  </div>
                  <div v-if="step.index == 3 && isStepActive(step.index)" class="d-block">
                    <button v-if="!brand" type="button" class="btn btn-primary" :disabled="processing"
                      @click="registerProfile">
                      <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                        style="margin: auto;" />
                      <span v-else>Register</span>
                    </button>
                    <div v-else class="mt-2">
                      <div v-if="brand.status === 'approved'">{{ brand.statusStr }}</div>
                      <div v-else-if="brand.status === 'failed'" class="text-danger">
                        {{ brand.statusStr }} <a v-b-tooltip.hover
                          :title="brand.failureReason"
                          class="fa fa-question-circle tooltip-icon" />
                      </div>
                      <div v-else>
                        <i class="fa fa-refresh fa-spin"></i> - {{ brand.statusStr }}
                      </div>
                      <div v-if="brand.status !== 'approved'" class="d-block">
                        <button type="button" class="btn btn-primary" :disabled="processing" @click="refreshProfile">
                          <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                            style="margin: auto;" />
                          <span v-else><i class="fa fa-refresh"></i> Refresh</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="hasError" class="row mt-4">
            <div v-for="(item, index) in errors" :key="index" class="col-md-12">
              <span class="text-danger font-weight-bold error-span" v-html="item"></span>
            </div>
          </div>
          <div class="row">
            <div v-if="isStepActive(3)" class="col-md-12 mt-4">
              <div class="alert alert-primary">
                <p class="font-weight-bold">By submitting this registration, You agree with $44 one-time fee is charge on your twilio account.</p>
              </div>
            </div>
            <div class="col-md-12 mt-4 mb-3">
              <span class="text-primary font-weight-bold">Business Information</span>
            </div>
            <div class="col-md-6">
              <text-input name="name" label="Legal Business Name" v-model="profileData.businessInfo.name"
                help="Enter the exact legal business name, as registered with the EIN. (E.g. Twilio Inc. rather than Twilio)"
                validate="required" :disabled="inputDisabled" />
            </div>
            <div class="col-md-6"></div>
            <div class="col-md-6">
              <label class="lelabel">Business Registration ID Type</label>
              <multiselect v-model="einTypeSelected" :options="listEinType" :multiple="false" placeholder="Select Type"
                track-by="value" label="name" :show-labels="false" :close-on-select="true" :clear-on-select="false"
                :preserve-search="true" :allowEmpty="false" :disabled="inputDisabled">
              </multiselect>
            </div>
            <div class="col-md-6">
              <text-input name="ein" label="Business Registration Number" v-model="profileData.businessInfo.ein"
                help="Enter the EIN Tax ID as it appears in the EIN listing" validate="required" :disabled="inputDisabled" />
            </div>
            <div class="col-md-6">
              <label class="lelabel">Business Regions of Operations</label>
              <multiselect v-model="businessRegionSelected" :options="listBusinessRegion" :multiple="false"
                placeholder="Select Region" track-by="value" label="name" :show-labels="false" :close-on-select="true"
                :clear-on-select="false" :preserve-search="true" :allowEmpty="false" :disabled="inputDisabled">
              </multiselect>
            </div>
            <div class="col-md-6">
              <text-input name="website" label="Website URL" v-model="profileData.businessInfo.website_url"
                validate="required" :disabled="inputDisabled"/>
            </div>
            <!-- <div class="col-md-6">
              <text-input name="ProfileID" label="Profile ID" v-model="profileData.profile_id" :disabled="true"/>
            </div> -->
          </div>
          <div class="row">
            <div class="col-md-12 mt-4 mb-3">
              <span class="text-primary font-weight-bold">Contact Information</span>
            </div>
            <div class="col-md-6">
              <text-input name="FirstName" label="First Name" v-model="profileData.contactInfo.firstName"
                validate="required" :disabled="inputDisabled" />
            </div>
            <div class="col-md-6">
              <text-input name="LastName" label="Last Name" v-model="profileData.contactInfo.lastName"
                validate="required" :disabled="inputDisabled" />
            </div>
            <div class="col-md-6">
              <text-input name="Email" label="Email" v-model="profileData.contactInfo.email" validate="required" :disabled="inputDisabled" />
            </div>
            <div class="col-md-6">
              <phone-number-input name="Phone" v-model="profileData.contactInfo.phone" label="Phone (SMS-enabled)" :disabled="inputDisabled"/>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12 mb-3">
              <span class="text-primary font-weight-bold b-block">Physical Address</span>
              <small class="d-block">( Enter the information as it appears in the EIN listing )</small>
            </div>
            <div class="col-md-6">
              <text-input name="Street" label="Street address (P.O. boxes not accepted)"
                v-model="profileData.addressInfo.street" validate="required" :disabled="inputDisabled" />
            </div>
            <div class="col-md-6">
              <text-input name="City" label="City" v-model="profileData.addressInfo.city" validate="required" :disabled="inputDisabled" />
            </div>
            <div class="col-md-6">
              <text-input name="State" label="State/Province" v-model="profileData.addressInfo.state"
                validate="required" :disabled="inputDisabled" />
            </div>
            <div class="col-md-6">
              <text-input name="PostalCode" label="Postal code" v-model="profileData.addressInfo.postal_code"
                validate="required" :disabled="inputDisabled" />
            </div>
            <div class="col-md-6">
              <label class="lelabel">Country</label>
              <multiselect v-model="countrySelected" :options="listCountries" :multiple="false"
                placeholder="Select Country" track-by="value" label="name" :show-labels="false" :close-on-select="true"
                :clear-on-select="false" :preserve-search="true" :allowEmpty="false" :disabled="!!profileData.address_id">
              </multiselect>
            </div>
            <div class="col-md-6"></div>
            <!-- <div class="col-md-6 mt-3">
              <text-input name="AddressID" label="Address ID" v-model="profileData.address_id" :disabled="true" />
            </div>
            <div class="col-md-6 mt-3">
              <text-input name="DocumentID" label="Document ID" v-model="profileData.document_id" :disabled="true" />
            </div> -->
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <router-link class="btn btn-danger btn-primary mr-2" :to="{ name: 'agency.twilioProfile.index' }">
                Back <i class="fa fa-arrow-left"></i>
              </router-link>
              <button v-if="showActionButton" class="btn btn-primary" :disabled="processing">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                  style="margin: auto;" />
                <span v-else>{{ buttonLabel }}</span>
              </button>
              <button v-if="showResubmit" type="button" class="btn btn-primary ml-2" :disabled="processing" @click="updateProfile">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                  style="margin: auto;" />
                <span v-else>Resubmit</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </vuestic-widget>
</template>
      
<script>
const { getCodeList } = require('country-list');
import VueScrollTo from 'vue-scrollto'

export default {
  data() {
    return {
      loading: false,
      processing: false,
      currentStep: 1,
      steps: [
        {
          index: 1,
          label: 'Information',
        },
        {
          index: 2,
          label: 'Validate',
        },
        {
          index: 3,
          label: 'Register',
        },
      ],
      listBusinessRegion: [],
      listEinType: [],
      listUseCase: [],
      listCountries: [],
      businessRegionSelected: null,
      einTypeSelected: null,
      countrySelected: null,
      profileData: {
        businessInfo: {
        },
        contactInfo: {
        },
        addressInfo: {
        },
      },
      showResubmit: false,
      errors: [],
    }
  },

  created() {
    this.profileData.sid = this.$route.params.id
  },

  mounted() {
    this.listBusinessRegion = [
      { value: 'USA_AND_CANADA', name: 'USA and Canada' },
      { value: 'AFRICA', name: 'Africa' },
      { value: 'ASIA', name: 'Asia' },
      { value: 'EUROPE', name: 'Europe' },
      { value: 'LATIN_AMERICA', name: 'Latin America' },
    ]

    this.listEinType = [
      { value: 'EIN', name: 'USA: Employer Identification Number (EIN)' },
      { value: 'DUNS', name: 'USA: DUNS Number (Dun & Bradstreet)' },
      { value: 'CBN', name: 'Canada: Canadian Business Number' },
      { value: 'CN', name: 'Great Britain: Company Number' },
      { value: 'ACN', name: 'Australia: Company Number from ASIC (ACN)' },
      { value: 'CIN', name: 'India: Corporate Identity Number' },
      { value: 'VAT', name: 'Estonia: VAT Number' },
      { value: 'VATRN', name: 'Romania: VAT Registration Number' },
      { value: 'RN', name: 'Israel: Registration Number' },
      { value: 'Other', name: 'Other' },
    ]

    let countries = getCodeList()
    for (let code of Object.keys(countries)) {
      this.listCountries.push({ value: code, name: countries[code] })
    }

    if (this.profileData.sid) {
      this.loadData();
    } else {
      this.businessRegionSelected = this.listBusinessRegion[0]
      this.einTypeSelected = this.listEinType[0]
      this.countrySelected = this.listCountries.find(item => item.value === 'us')
    }
  },

  watch: {
    'businessRegionSelected': {
      handler: function (newValue, oldValue) {
        if (newValue && newValue != oldValue) {
          this.profileData.businessInfo.region = newValue.value
        }
      }
    },
    'einTypeSelected': {
      handler: function (newValue, oldValue) {
        if (newValue && newValue != oldValue) {
          this.profileData.businessInfo.einType = newValue.value
        }
      }
    },
    'countrySelected': {
      handler: function (newValue, oldValue) {
        if (newValue && newValue != oldValue) {
          this.profileData.addressInfo.country = newValue.value
        }
      }
    },
    'profileData.step': {
      handler: function (newValue, oldValue) {
        if (newValue && newValue != oldValue) {
          this.currentStep = newValue
        }
      }
    },
  },

  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      if (element)
        VueScrollTo.scrollTo(element, 200)
    },
    
    loadData(reload = true) {
      if (reload)
        this.loading = true
      this.processing = true
      this.$store
        .dispatch('twilio/profileGet', this.profileData.sid)
        .then((res) => {
          this.profileData = {
            ...res.data
          }
          this.initData();
          this.loading = false
          this.processing = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          this.processing = false
        })
    },

    initData() {
      this.businessRegionSelected = this.listBusinessRegion.find(item => item.value === this.profileData.businessInfo.region)
      this.einTypeSelected = this.listEinType.find(item => item.value === this.profileData.businessInfo.einType)
      this.countrySelected = this.listCountries.find(item => item.value === this.profileData.addressInfo.country)
    },

    createProfile() {
      this.errors = []
      this.processing = true
      let params = {
        ...this.profileData
      }
      this.$store
        .dispatch('twilio/profileCreate', params)
        .then((res) => {
          this.processing = false
          if (!res.success) {
            this.errors = [res.message]
            this.goto('agencyForm')
          }
          if (res.data.sid) {
            this.profileData = {
              ...res.data
            }
            if (this.profileData.sid) {
              this.$router.push({ name: 'agency.twilioProfile.edit', params: { id: this.profileData.sid } });
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.processing = false
        })
    },

    updateProfile() {
      this.errors = []
      this.processing = true
      let params = {
        ...this.profileData
      }
      this.$store
        .dispatch('twilio/profileCreate', params)
        .then((res) => {
          this.processing = false
          if (!res.success) {
            this.errors = [res.message]
            Vue.$toast.open({
              message: res.message,
              type: 'error',
            })
            this.goto('agencyForm')
          } else {
            this.showResubmit = false;
            this.loadData();
          }
        })
        .catch((err) => {
          console.log(err)
          this.processing = false
        })
    },

    validateProfile() {
      this.processing = true

      this.$store
        .dispatch('twilio/validateCreate', this.profileData.sid)
        .then((res) => {
          if (!res.success) {
            this.errors = res.data
            Vue.$toast.open({
              message: res.message,
              type: 'error',
            })
            this.showResubmit = true;
          } else {
            this.loadData();
          }
          this.processing = false
        })
        .catch((err) => {
          console.log(err)
          this.processing = false
        })
    },

    registerProfile() {
      this.processing = true

      this.$store
        .dispatch('twilio/registerProfile', this.profileData)
        .then((res) => {
          if (!res.success) {
            this.errors = res.data
            Vue.$toast.open({
              message: res.message,
              type: 'error',
            })
          } else {
            this.loadData();
          }
          this.processing = false
        })
        .catch((err) => {
          console.log(err)
          this.processing = false
        })
    },

    isStepActive(val) {
      return this.currentStep === val
    },
    refreshProfile() {
      this.loadData(false)
    },
    submit() {
      if (this.currentStep === 1) {
        this.createProfile()
      } else if (this.currentStep === 2) {
        this.validateProfile()
      } else if (this.currentStep === 3) {
        if (!this.brand) {
          this.registerProfile()
        }
      }
    },
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },
    hasError() {
      return !!this.errors.length
    },
    showActionButton() {
      if (this.brand)
        return false
      if (this.currentStep === 1 && this.profileData.profile_id)
        return false
      return true
    },
    buttonLabel() {
      let res = 'Create'
      if (this.currentStep === 2) {
        res = 'Validate Now'
      } else if (this.currentStep === 3) {
        res = 'Register'
        if (this.brand && this.brand.status === 'failed') {
          res = 'Resubmit'
        }
      }
      return res
    },
    brand() {
      return this.profileData.brand
    },
    inputDisabled() {
      if (this.currentStep === 3)
        return true;
      return false;
    }
  }
}
</script>
      
<style lang="scss">
.twilioProfie-create-page {
  max-width: 1024px;
  padding-top: 30px;
  span.error-span {
    a {
      color: #e34a4a !important;
    }
  }
}
</style>
<style scoped lang="scss">
.step-wrapper {
  .step-header {
    display: flex;
    justify-content: space-between;
    align-items: start;

    .step-header-item {
      padding: 0 40px;
      cursor: pointer;

      &.active {
        .number {
          border-width: 5px;
          font-size: 28px;
        }

        .label {
          font-weight: bold;
          font-style: italic;
        }
      }

      .number {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        border: 2px solid;
        border-radius: 50%;
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
      }

      .label {
        display: block;
        text-align: center;
        padding-top: 10px;
        font-size: 17px;
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
      }

      &.active {
        color: $rb-blue;
      }
    }
  }

  .step-content {
    .step-item {
      padding: 40px 0 20px;
    }
  }
}</style>